<template>
    <div v-loading="loading" element-loading-text="正在飞速加载中...">
        <div>
            <el-input v-model="filterText" placeholder="快速查找节点" clearable />
        </div>
        <div class="m-b-50">
            <el-tree
                ref="menuTree"
                :data="data"
                show-checkbox
                default-expand-all
                :props="{ children: 'children', label: 'permission_name', id: 'id' }"
                node-key="id"
                :check-strictly="this.checkStrictly"
                :filter-node-method="filterNode"
                @check-change="handleCheckChange"
                @node-click="handleNodeClick"
            ></el-tree>
        </div>
        <div class="m-t-50">
            <el-button type="primary" @click="save()" class="w-px-min-300" :disabled="disabled_btn">保存</el-button>
        </div>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent } from 'vue'
import { http } from '@/assets/js'

export default defineComponent({
    name: 'PowerSet',
    inject: ['reload'],
    props: {
        group_id: {
            type: [String, Number],
            required: true
        }
    },
    watch: {
        group_id: {
            handler(newVal) {
                this.getPowerInfoEvent(newVal)
            },
            immediate: true,
            deep: true
        },
        filterText(val) {
            this.$refs.menuTree.filter(val)
        }
    },
    data() {
        return {
            filterText: '',

            // 操作权限-相关字段
            data: [],
            selectIds: [],
            showids: [],

            loading: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            userinfo: JSON.parse(localStorage.getItem('userinfo')), //获取用户信息

            checkStrictly: true,
            disabled_btn: false
        }
    },
    methods: {
        // 获取权限tree
        async getPowerInfoEvent(group_id) {
            this.checkStrictly = true
            this.loading = true

            let response = await http.get(`admin/roles/permissions/${group_id}`)
            this.data = response.permissionsList
            this.setchecked()
            this.showids = response.haspermissions
            this.$refs.menuTree.setCheckedKeys(this.showids)
            setTimeout(() => {
                this.checkStrictly = false
            }, 500)

            this.loading = false
        },
        // 节点筛选的方法
        filterNode(value, data) {
            if (!value) return true
            return data['permission_name'].indexOf(value) !== -1
        },
        // 点击复选框触发
        handleCheckChange(data, ischange, ischange_p) {
            if (ischange || ischange_p) {
                // 插入数组
                this.selectIds.push(data.id)
            } else {
                this.selectIds = this.selectIds.filter((n) => n !== data.id)
            }
        },
        // 点击时触发
        handleNodeClick({ node }) {
            this.checkStrictly = false
        },
        // 【操作权限】-保存已选择的权限
        async save() {
            this.disabled_btn = true
            let ban_node = this.$refs.menuTree.getHalfCheckedNodes()?.map((n) => n.id)
            this.selectIds = [...this.$refs.menuTree.getCheckedKeys(), ...ban_node]

            await http.put(`admin/roles/permissions/${this.group_id}`, { perimissions: this.selectIds })
            this.$emit('close-drawer', true)
            this.disabled_btn = false
        },
        // 【操作权限】-js递归重组数据
        setchecked() {
            this.showids = []
            for (var i = 0; i < this.data.length; i++) {
                if (this.data[i]['checked'] == true) {
                    this.showids.push(this.data[i]['id'])
                }
                if ('children' in this.data[i] && this.data[i]['children'].length > 0) {
                    this.setcheckedsub(this.data[i])
                }
            }
        },
        setcheckedsub(node) {
            for (var i = 0; i < node.children.length; i++) {
                // 判断-companyid是否为1;
                if (this.userinfo.companyid != 1) {
                    // 公司不为1：移除【平台打印分佣】权限
                    if (node.children[i]['id'] == 553) {
                        let getcontentdata = node.children.filter((n) => n.id != '553')
                        node.children = getcontentdata
                    }
                }

                if (node.children[i]['checked'] == true) {
                    this.showids.push(node.children[i]['id'])
                }
                if ('children' in node.children[i] && node.children[i]['children'].length > 0) {
                    this.setcheckedsub(node.children[i])
                }
            }
        }
    }
})
</script>

<style scoped></style>
