<template>
    <div>
        <aw-tabler :table="table">
            <template #drawercontent>
                <div v-if="table.drawer.flex == 'set'">
                    <PowerSet
                        :group_id="table.drawer.form.id"
                        @close-drawer="
                            () => {
                                table.drawer.show = false
                                table.action()

                                resultLoginEvent()
                            }
                        "
                    />
                </div>
                <div v-else>
                    <PowerAddEdit
                        :modelInfo="{ flex: table.drawer.flex, form: table.drawer.form }"
                        @close-drawer="
                            () => {
                                table.drawer.show = false
                                table.action()
                            }
                        "
                    />
                </div>
            </template>
        </aw-tabler>
    </div>
</template>
<script setup lang="jsx">
import { http, util, dict, tool } from '@/assets/js'
import { reactive, ref } from 'vue'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import PowerSet from './PowerSet.vue'
import PowerAddEdit from './PowerAddEdit.vue'
import router from '@/router'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router'

const route = useRoute()
// 当前页面操作权限集
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.role, 'component'))

// 远程select选中
const selectChangeEvent = (data) => {
    table.filter.mobile = data
}

const resultLoginEvent = async () => {
    let menu_response = await http.get('/admin/adminUser/getPermissions')
    let roles = tool.getAllPowerlimite(menu_response)
    let menu = tool.addPathToChildren(menu_response, roles)

    router.addRoute({
        name: 'index',
        path: '/index',
        meta: { name: '主页' },
        component: () => import('@/view/index/index.vue'),
        children: menu
    })
    localStorage.setItem('mymodels', JSON.stringify(menu)) //存储导航信息

    ElMessage({
        type: 'success',
        message: '保存成功，权限将在1秒后刷新页面后生效',
        duration: 1000,
        onClose: () => {
            location.reload()
        }
    })
}

const table = reactive({
    title: '权限管理',
    select: false,
    loading: false,
    datas: [],
    columns: [
        ...tableColumn([
            { title: '权限名称', key: 'role_name', minWidth: '100px' },
            { title: '权限描述', key: 'description', minWidth: '100px' },
            { title: '日志标识', key: 'identify', minWidth: '100px' },
            { title: '添加时间', key: 'addtime', minWidth: '100px', time: true }
        ]),
        {
            fixed: 'right',
            title: '操作',
            key: 'operate',
            minWidth: '160px',
            buttons: [
                {
                    name: '权限设置',
                    props: { type: 'primary', size: 'small' },
                    show: () => powerlimits.includes('qxgl_qxsz'),
                    action: async (data) => {
                        table.drawer.show = true
                        table.drawer.title = '权限设置'

                        table.drawer.flex = 'set'
                        table.drawer.form.id = data.id
                    }
                },
                {
                    name: '编辑',
                    props: { type: 'primary', size: 'small' },
                    show: () => powerlimits.includes('qxgl_bj'),
                    action: async (data) => {
                        table.drawer.show = true
                        table.drawer.title = '编辑'

                        table.drawer.flex = 'edit'
                        table.drawer.form = {
                            id: data.id,
                            name: data.role_name,
                            remark: data.description,
                            identify: data.identify
                        }
                    }
                }
            ]
        }
    ],
    action: async (params) => {
        if (powerlimits.includes('qxgl_lb')) {
            table.loading = true
            let result = await http.get('/admin/roles', { params: { ...params, ...route.query } })
            table.datas = result
            table.loading = false
        } else {
            table.datas = []
        }
        return
    },
    extras: [
        {
            name: '添加',
            props: { type: 'primary', size: 'small' },
            show: () => powerlimits.includes('qxgl_tj'),
            action: () => {
                table.drawer.show = true
                table.drawer.title = '添加'

                table.drawer.flex = 'add'
                table.drawer.form = {
                    name: '',
                    remark: '',
                    identify: ''
                }
            }
        }
    ],
    drawer: reactive({
        flex: null,
        form: {},
        show: false,
        title: '',
        close: () => {
            table.drawer.show = false
        }
    })
})
</script>
<style scoped></style>
