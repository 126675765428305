<template>
    <div>
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="权限名称:" prop="name">
                <el-input v-model="form.name" placeholder="请输入" clearable maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="权限描述:" prop="remark">
                <el-input v-model="form.remark" type="textarea" placeholder="请输入" clearable maxlength="200" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="日志标识:" prop="identify">
                <el-input v-model="form.identify" type="textarea" placeholder="请输入" clearable maxlength="200" show-word-limit></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="saveEvent('formRef')" :disabled="disabled_btn">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { ref } from 'vue'
import { http } from '@/assets/js'

export default {
    name: 'PowerAddEdit',
    inject: ['reload'],
    props: { modelInfo: { type: Object, required: true } },
    watch: {
        modelInfo: {
            handler(newVal) {
                this.model_info = newVal
                this.form = this.model_info.form
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            model_info: {},
            form: {
                id: '',
                name: '',
                remark: '',
                identify: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '权限名称为必填项',
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: true,
                        message: '权限描述为必填项',
                        trigger: 'blur'
                    }
                ],
                identify: [
                    {
                        required: true,
                        message: '日志标识为必填项',
                        trigger: 'blur'
                    }
                ]
            },
            disabled_btn: false
        }
    },
    methods: {
        saveEvent(formName) {
            let getdatas = this.form
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.disabled_btn = true
                    if (this.model_info.flex == 'add') {
                        await http.post(
                            '/admin/roles',
                            { role_name: getdatas.name, description: getdatas.remark, identify: getdatas.identify, ...{ parent_id: 0, data_range: '1' } },
                            { sMsg: true }
                        )
                    } else {
                        await http.put(
                            `admin/roles/${getdatas.id}`,
                            { role_name: getdatas.name, description: getdatas.remark, identify: getdatas.identify, ...{ parent_id: 0, data_range: '1' } },
                            { sMsg: true }
                        )
                    }
                    this.$emit('close-drawer', true)
                    this.$refs[formName].resetFields()
                    this.disabled_btn = false
                } else {
                    return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped></style>
